// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-events-archive-page-js": () => import("./../src/templates/events-archive-page.js" /* webpackChunkName: "component---src-templates-events-archive-page-js" */),
  "component---src-templates-news-archive-page-js": () => import("./../src/templates/news-archive-page.js" /* webpackChunkName: "component---src-templates-news-archive-page-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-single-events-js": () => import("./../src/templates/single-events.js" /* webpackChunkName: "component---src-templates-single-events-js" */),
  "component---src-templates-single-news-js": () => import("./../src/templates/single-news.js" /* webpackChunkName: "component---src-templates-single-news-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

